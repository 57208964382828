import { render, staticRenderFns } from "./AdsCompany.vue?vue&type=template&id=aeaa2ade&scoped=true&"
import script from "./AdsCompany.vue?vue&type=script&lang=js&"
export * from "./AdsCompany.vue?vue&type=script&lang=js&"
import style0 from "./AdsCompany.vue?vue&type=style&index=0&id=aeaa2ade&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aeaa2ade",
  null
  
)

export default component.exports