<script>
import PageTitle from '@/components/PageTitle.vue';
import UpdateDeleteAction from '@/components/UpdateDeleteAction.vue';
import AdsStatistics from '@/views/ads/AdsStatistics.vue';
import { BButton, BCol, BFormGroup, BFormInput, BPagination, BRow, BTable } from 'bootstrap-vue';
import vSelect from 'vue-select';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import api from '@/services/api';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import { createPopper } from '@popperjs/core';
import { sortObjectValues } from '@/util/helper';
import { isPrimitive } from '@/util/inspect';
import { formatDate } from '@/util/date';

export default {
    name: 'AdsCompany',
    components: {
        PageTitle,
        UpdateDeleteAction,
        ProgressLinear,
        AdsStatistics,
        BTable,
        BRow,
        BCol,
        BPagination,
        BFormGroup,
        BFormInput,
        BButton,
        vSelect
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            companies: [],
            dataForModal: {
                name: '',
                start_date: null,
                end_date: null,
                budget: null
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10,
                totalPage: 1
            },
            perPageList: [10, 20, 30, 50, 100],
            loading: false,
            placement: 'top'
        };
    },

    computed: {
        tableHeader() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.name')
                },
                {
                    key: 'start_date',
                    label: this.$t('titles.start_date'),
                    formatter: (start_date) => formatDate(start_date)
                },
                {
                    key: 'end_date',
                    label: this.$t('titles.end_date'),
                    formatter: (end_date) => formatDate(end_date)
                },
                {
                    key: 'budget',
                    label: this.$t('titles.budget')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.companies && this.companies.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.loading;
        }
    },

    watch: {
        '$route.query': {
            handler() {
                this.pagination.current = parseInt(this.$route.query.page);
                this.pagination.perPage = parseInt(this.$route.query.limit);
                this.getCompanies();
            },
            deep: true
        }
    },
    async mounted() {
        this.query.page = parseInt(this.$route.query.page) || 1;
        this.query.limit = parseInt(this.$route.query.limit) || 10;

        this.loading = true;
        await this.getCompanies();
        this.loading = false;
    },
    methods: {
        changeCurrentPage(page) {
            const currentPage = this.query.page;
            this.pagination.current = currentPage;
            if (page === currentPage) return;
            if (this.pagination.current > this.pagination.totalItem) {
                this.query.page = 1;
            }
            this.replaceRouter({ ...this.query, page });
        },
        changeFetchLimit() {
            const { query } = this;
            const isNotUpdate = query.limit?.toString() === this.pagination.perPage.toString();
            if (isPrimitive(query.limit) && isNotUpdate) return;
            const localQuery = {
                ...this.query,
                page: 1
            };
            const limit = this.pagination.perPage;
            this.replaceRouter({ ...localQuery, limit });
        },
        async getCompanies() {
            const query = sortObjectValues(this.query);
            const { data } = await api.ads.getCompanies(query);
            this.companies = data.result;
            this.pagination = data.pagination;
        },

        async create(data) {
            await api.ads.createCompany(data).then(({ data }) => {
                this.companies.unshift(data);
            });
        },

        async update(data) {
            await api.ads.updateCompany(data).then(() => {});
        },

        async deleteCompany(id, index) {
            await api.ads
                .deleteCompany({ id })
                .then(() => {
                    this.$swal({
                        icon: 'success',
                        title: this.$t('success.deleted'),
                        customClass: {
                            confirmButton: 'btn btn-success'
                        }
                    });
                    this.companies.splice(index, 1);
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: this.$t('error.smth'),
                        customClass: {
                            confirmButton: 'btn btn-success'
                        }
                    });
                });
        },

        submitModal() {
            this.$refs.createUpdateValidation.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;

                    try {
                        if (this.updatingItem) {
                            body.id = this.updatingItem.id;
                            await this.update(body);
                        } else {
                            await this.create(body);
                            this.$toast.success(this.$t('success.added'));
                        }
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    } finally {
                        this.isOpenModal = false;
                        await this.getCompanies();
                    }
                }
            });
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        // for v-select position
        withPopper(dropdownList, component, { width }) {
            dropdownList.style.width = width;
            const popper = createPopper(component.$refs['toggle'], dropdownList, {
                placement: this.placement,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -1]
                        }
                    },
                    {
                        name: 'toggleClass',
                        enabled: true,
                        phase: 'write',
                        fn({ state }) {
                            component.$el.classList.toggle('drop-up', state.placement === 'top');
                        }
                    }
                ]
            });
            return () => popper.destroy();
        }
    }
};
</script>

<template>
    <div>
        <ads-statistics />

        <page-title>
            <template #title>
                {{ $t('company') }}
            </template>
            <template v-if="hasAccess('ad_companies', 'create')" #button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <b-row>
            <!--  TABLE  -->
            <b-col cols="12">
                <div class="card">
                    <progress-linear v-if="loading" />
                    <b-table
                        v-else
                        :fields="tableHeader"
                        :items="companies"
                        :responsive="true"
                        borderless
                        outlined
                        striped
                    >
                        <template #cell(actions)="{ item, index }">
                            <div class="d-flex align-items-center">
                                <b-button
                                    v-if="hasAccess('ads', 'view')"
                                    v-b-tooltip.hover.top="`${$t('button.view')}`"
                                    :to="{
                                        name: 'ads-advertisement',
                                        params: {
                                            id: item.id
                                        }
                                    }"
                                    class="btn-icon"
                                    variant="flat-success"
                                >
                                    <feather-icon icon="EyeIcon" />
                                </b-button>
                                <update-delete-action
                                    :delete-access="{
                                        section: 'ad_companies',
                                        permission: 'delete'
                                    }"
                                    :delete-handler="deleteCompany"
                                    :index="index"
                                    :item="item"
                                    :update-access="{
                                        section: 'ad_companies',
                                        permission: 'update'
                                    }"
                                    @openModal="openModalToUpdate"
                                />
                            </div>
                        </template>
                    </b-table>
                </div>
            </b-col>
        </b-row>

        <!--  PAGINATION  -->
        <b-row v-if="!(pagination.current === 1 && companies.length === 0)">
            <b-col cols="4">
                <div class="d-flex align-items-center">
                    <span> Per page: </span>
                    <v-select
                        v-model="pagination.perPage"
                        :calculate-position="withPopper"
                        :options="perPageList"
                        :placement="placement"
                        append-to-body
                        class="ml-2"
                        style="width: 180px"
                        @input="changeFetchLimit"
                    />
                </div>
            </b-col>
            <b-col cols="8">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                    @input="changeCurrentPage"
                />
            </b-col>
        </b-row>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="false"
            :no-close-on-backdrop="false"
            :title="$t('add.news')"
            cancel-variant="danger"
            centered
            size="xl"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateValidation">
                <b-row>
                    <!--    NAME   -->
                    <b-col cols="6">
                        <label>
                            {{ $t('titles.title') }}
                        </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('enter.title')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name"
                                    :placeholder="$t('enter.title')"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    BUDGET    -->
                    <b-col cols="6">
                        <label class="capitalize">
                            {{ $t('budget') }}
                        </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('budget')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.budget"
                                    :placeholder="$t('enter.field', { name: $t('budget') })"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                    type="number"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    START DATE    -->
                    <b-col cols="6">
                        <ValidationProvider :name="`${$t('titles.title')} (uz)`" rules="required">
                            <label>{{ $t('titles.start_date') }}</label>
                            <b-form-datepicker
                                v-model="dataForModal.start_date"
                                class="mb-1"
                                style="z-index: 99"
                            />
                        </ValidationProvider>
                    </b-col>

                    <!--    END DATE    -->
                    <b-col cols="6">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} (uz)`"
                            rules="required"
                        >
                            <label>{{ $t('titles.end_date') }}</label>
                            <b-form-datepicker
                                v-model="dataForModal.end_date"
                                :state="errors.length > 0 ? false : null"
                                class="mb-1"
                                style="z-index: 99"
                            />
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<style lang="scss" scoped>
::v-deep .modal-dialog.modal-xl .modal-body {
    overflow-y: visible !important;
}

.v-select.drop-up.vs--open .vs__dropdown-toggle {
    border-radius: 0 0 4px 4px;
    border-top-color: transparent;
    border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement='top'] {
    border-radius: 4px 4px 0 0;
    border-top-style: solid;
    border-bottom-style: none;
    box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
</style>
