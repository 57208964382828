<script>
import { BAvatar, BCard, BCardBody } from 'bootstrap-vue';
import { hasOwnProperty } from '@/util/object.util';

export default {
    components: {
        BCard,
        BCardBody,
        BAvatar
    },
    props: {
        icon: {
            type: String,
            required: true
        },
        statistic: {
            type: [Number, String],
            required: true
        },
        statisticTitle: {
            type: String,
            default: ''
        },
        color: {
            type: String,
            default: 'primary'
        }
    },
    computed: {
        hasStatisticSlot() {
            return hasOwnProperty(this.$slots, 'statistic');
        }
    }
};
</script>

<template>
    <b-card no-body>
        <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                    <slot name="statistic" />
                    <span v-if="!hasStatisticSlot">
                        {{ statistic }}
                    </span>
                </h2>
                <span>{{ statisticTitle }}</span>
            </div>
            <b-avatar :variant="`light-${color}`" size="45">
                <feather-icon size="21" :icon="icon" />
            </b-avatar>
        </b-card-body>
    </b-card>
</template>
