import Core from '@/services/axios/core.axios';

class StatisticsService extends Core {
    constructor() {
        super({
            endpoint: 'statistics'
        });
    }
}

export const statisticsApi = new StatisticsService();
