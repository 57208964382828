<script>
import { BButton, VBTooltip } from 'bootstrap-vue';

export default {
    name: 'TableActionBtns',
    components: {
        BButton,
        VBTooltip
    },
    directives: {
        'b-tooltip': VBTooltip
    },
    props: {
        item: Object,
        index: [Number, String],
        deleteHandler: Function,
        deleteAccess: {
            type: Object,
            default: () => ({
                section: '',
                permission: ''
            })
        },
        updateAccess: {
            type: Object,
            default: () => ({
                section: '',
                permission: ''
            })
        }
    },
    methods: {
        openModal() {
            this.$emit('openModal', this.item);
        },

        deleteItem() {
            this.$swal({
                title: this.$t('success.delete.text_1'),
                text: this.$t('success.delete.text_2'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('success.delete.button_1'),
                cancelButtonText: this.$t('success.delete.button_2'),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(async ({ isConfirmed }) => {
                if (isConfirmed) {
                    await this.deleteHandler(
                        this.item.uuid ? this.item.uuid : this.item.id,
                        this.index
                    )
                        .then(() => {})
                        .catch(() => {});
                }
            });
        }
    }
};
</script>

<template>
    <div class="d-flex justify-content-end align-items-center">
        <b-button
            v-if="hasAccess(updateAccess.section, updateAccess.permission)"
            variant="flat-dark"
            class="btn-icon"
            @click="openModal"
            v-b-tooltip.hover.top="`${$t('button.edit')}`"
        >
            <feather-icon icon="EditIcon" />
        </b-button>

        <b-button
            v-if="deleteHandler && hasAccess(deleteAccess.section, deleteAccess.permission)"
            variant="flat-danger"
            class="btn-icon"
            @click="deleteItem"
            v-b-tooltip.hover.top="`${$t('button.delete')}`"
        >
            <feather-icon icon="Trash2Icon" />
        </b-button>
    </div>
</template>

<style scoped></style>
