<script>
import ICountUp from 'vue-countup-v2';
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue';
import PageTitle from '@/components/PageTitle.vue';
import { BSpinner } from 'bootstrap-vue';
import { statisticsApi } from '@/services/statistics/statistics.service';

export default {
    name: 'AdsStatistics',
    components: {
        StatisticCardHorizontal,
        PageTitle,
        ICountUp,
        BSpinner
    },
    data() {
        return {
            delay: 100,
            endVal: 120500,
            countUpOptions: {
                useEasing: true,
                useGrouping: true,
                separator: ' ',
                decimal: '.',
                prefix: '',
                suffix: ''
            },
            statistics: {
                uzmobile: {
                    app: 0,
                    telegram: 0
                },
                alchiroq: {
                    app: 0,
                    telegram: 0
                }
            }
        };
    },
    computed: {
        statisticsList() {
            return [
                {
                    statistic: this.statistics.alchiroq.app,
                    title: 'Alchiroq app',
                    icon: 'BarChart2Icon',
                    color: 'success'
                },
                {
                    statistic: this.statistics.alchiroq.telegram,
                    title: 'Alchiroq telegram',
                    icon: 'TrendingUpIcon',
                    color: 'warning'
                },
                {
                    statistic: this.statistics.uzmobile.app,
                    title: 'Uzmobile App',
                    icon: 'SendIcon',
                    color: 'primary'
                },
                {
                    statistic: this.statistics.uzmobile.telegram,
                    title: 'Uzmobile telegram',
                    icon: 'BarChartIcon',
                    color: 'danger'
                }
            ];
        }
    },
    created() {
        this.fetchStatistics();
    },
    methods: {
        async fetchStatistics() {
            const {
                data: { uzmobile, alchiroq }
            } = await statisticsApi.create({});
            this.statistics.uzmobile = uzmobile;
            this.statistics.alchiroq = alchiroq;
        }
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title> {{ $t('statistics') }}</template>
        </page-title>
        <b-row>
            <b-col lg="3" sm="6" v-for="statisticItem in statisticsList" :key="statisticItem.title">
                <statistic-card-horizontal
                    :statistic="statisticItem.statistic"
                    :statistic-title="statisticItem.title"
                    :icon="statisticItem.icon"
                    :color="statisticItem.color"
                >
                    <template #statistic>
                        <i-count-up
                            v-if="statisticItem.statistic"
                            :delay="delay"
                            :end-val="statisticItem.statistic"
                            :options="countUpOptions"
                        />
                        <b-spinner :variant="statisticItem.color" label="Loading..." v-else />
                    </template>
                </statistic-card-horizontal>
            </b-col>
        </b-row>
    </div>
</template>

<style scoped></style>
